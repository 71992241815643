import { BASE_URL, HOST } from '../.env.production.js';

if (!BASE_URL) {
    throw new Error('BASE_URL is not defined');
} else if (!HOST) {
    throw new Error('HOST is not defined');
}

export const apiUrl = BASE_URL;
export const host = HOST;
export const version = "1";
export const applicationUrl = window.location.origin;